const projects = [
    {
        "name": "Web Development",
        "description": "Dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
        "image": require("./../images/projects/p-1.jpg"),
        "url": "https://soban.me"
    },
    {
        "name": "Web Development",
        "description": "Dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
        "image": require("./../images/projects/p-2.jpg"),
        "url": "https://soban.me"
    },
    {
        "name": "Web Development",
        "description": "Dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
        "image": require("./../images/projects/p-3.jpg"),
        "url": "https://soban.me"
    },
    {
        "name": "Web Development",
        "description": "Dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
        "image": require("./../images/projects/p-4.jpg"),
        "url": "https://soban.me"
    },
    {
        "name": "Web Development",
        "description": "Dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
        "image": require("./../images/projects/p-5.jpg"),
        "url": "https://soban.me"
    },
    {
        "name": "Web Development",
        "description": "Dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
        "image": require("./../images/projects/p-6.jpg"),
        "url": "https://soban.me"
    },
]

export default projects;