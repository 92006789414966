import { Link, useLocation } from "react-router-dom";
import projects from "../data/projects"

const Portfolio = () => {
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/")[1];

    return (
        <section className="portfolio_section layout_padding">
            <div className="container">
                <div className="heading_container">
                    <h2>
                        Portfolio
                    </h2>
                    <p>
                        We are proud to work with
                    </p>
                </div>
                <div className="layout_padding2-top">
                    <div className="row">
                        {
                            projects.map((project, index) => (
                                <div key={`project-${index}`} className="col-md-4 col-sm-6">
                                    <div className="img-box">
                                        <img src={project.image} alt="" />
                                        <a href={project.url} target={"_blank"} rel="noreferrer">
                                            <img src={require("./../images/link.png")} alt="" />
                                        </a>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {
                    path !== "portfolio" && (<div className="btn-box">
                        <Link to="/portfolio">Read More</Link>
                    </div>)
                }
            </div>
        </section>
    );
};

export default Portfolio;