import { Link, useLocation } from "react-router-dom";
import aboutImg from "../images/about-img.png"
const About = () => {
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/")[1];
    return (
        <section className="about_section layout_padding">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="img-box">
                            <img src={aboutImg} alt="" />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="detail-box">
                            <div className="heading_container">
                                <h2>
                                    About Us
                                </h2>
                            </div>
                            <p>
                                Stackiox is software consulting agency that help businesses and startups.
                            </p>
                            {
                                path !== "about" && (<Link to="/about">Read More</Link>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;