const ThankYou = () => {
    return (
        <section className="contact_section layout_padding">
            <div className="container">
                <h3 className="text-center">
                    Thank you<br/>
                    We will get back to you soon
                </h3>
            </div>
        </section>
    );
};

export default ThankYou;