const ContactForm = () => {
    return (
        <section className="contact_section layout_padding">
            <div className="container">
                <div className="heading_container">
                    <h2>
                        Request A Call Back
                    </h2>
                </div>
                <div>
                    <div>
                        <div className="row">
                            <div className="col-md-9 mx-auto">
                                <div className="contact-form">
                                <form action="https://public.herotofu.com/v1/544eee00-6e3e-11ed-a377-655c67143cec" method="post">
                                        <div>
                                            <input type="text" name="Name" placeholder="Full Name" required />
                                        </div>
                                        <div>
                                            <input type="email" name="Email" placeholder="Email Address" required />
                                        </div>
                                        <div>
                                            <input type="phone" name="Phone" placeholder="Phone Number" required />
                                        </div>
                                        <div>
                                            <textarea type="text" name="Message" placeholder="Message" className="input_message" rows={8} required />
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn_on-hover" value="Download CTA">
                                                Send
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;