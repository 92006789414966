import { Link } from "react-router-dom";
const HeroSlider = () => {
    return (
        <div className="hero_area">
            <section className="slider_section">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="detail_box">
                                            <h1>
                                                WE HELP YOU TO MAKE <br />
                                                BETTER <span>SOFTWARE</span>
                                            </h1>
                                            <p>
                                                It is a long established fact that a reader will be distracted by the readable content of a page
                                                when looking
                                            </p>
                                            <Link className="nav-link" to="/about"> Read More</Link>
                                            {/* <a href={"#"}>
                                                Read More
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="col-md-5 offset-md-1">
                                        <div className="img-box">
                                            <img src={require("./../images/slider-img.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="carousel-item">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="detail_box">
                                            <h1>
                                                WE HELP YOU TO MAKE <br />
                                                BETTER <span>SOFTWARE</span>
                                            </h1>
                                            <p>
                                                It is a long established fact that a reader will be distracted by the readable content of a page
                                                when looking
                                            </p>
                                            <a href="#">
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-5 offset-md-1">
                                        <div className="img-box">
                                            <img src={require("./../images/slider-img.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="detail_box">
                                            <h1>
                                                WE HELP YOU TO MAKE <br />
                                                BETTER <span>SOFTWARE</span>
                                            </h1>
                                            <p>
                                                It is a long established fact that a reader will be distracted by the readable content of a page
                                                when looking
                                            </p>
                                            <a href={"#"}>
                                                Read More
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-5 offset-md-1">
                                        <div className="img-box">
                                            <img src={require("./../images/slider-img.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="carousel_btn-container">
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="sr-only">Next</span>
                        </a>
                    </div> */}
                </div>
            </section>
        </div>
    );
};

export default HeroSlider;