const services = [
    {
        "name": "Web Development",
        "description": "Dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
        "image1Url": require("./../images/services/s-1.png"),
        "image2Url": require("./../images/services/s-1-color.png")
    },
    {
        "name": "Mobile Development",
        "description": "Dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
        "image1Url": require("./../images/services/s-2.png"),
        "image2Url": require("./../images/services/s-2-color.png")
    },
    {
        "name": "Software Consultancy",
        "description": "Dipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim",
        "image1Url": require("./../images/services/s-3.png"),
        "image2Url": require("./../images/services/s-3-color.png")
    }
]

export default services;