import locationIcon from '../images/location-white.png';
import phoneIcon from '../images/telephone-white.png';
import emailIcon from '../images/envelope-white.png';
// import instaIcon from '../images/insta.png';
import logo from '../images/stackiox.png';

const Footer = () => {
    return (
        <div className="footer_bg">
            {/* info section */}
            < section className="info_section " >
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mb-4 mb-md-0 d-flex d-md-block flex-column align-items-center text-center text-md-left ">
                            <div className="info_info">
                                <h5>
                                    <img src={logo} alt="Stackiox" height={35} />
                                </h5>
                                <p>
                                    We nurture ecosystems of success by harnessing intelligent technologies and capitalizing on human ingenuity.
                                </p>
                            </div>
                        </div>                    
                        <div className="col-md-3 mb-4 mb-md-0 d-flex d-md-block flex-column align-items-center text-center text-md-left ">
                            {/* <div className="info_insta">
                                <h5>
                                    Instagram
                                </h5>
                                <div className="insta_container">
                                    <div>
                                        <a href="#">
                                            <div className="insta-box b-1">
                                                <img src={instaIcon} alt="" />
                                            </div>
                                        </a>
                                        <a href="#">
                                            <div className="insta-box b-2">
                                                <img src={instaIcon} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="#">
                                            <div className="insta-box b-3">
                                                <img src={instaIcon} alt="" />
                                            </div>
                                        </a>
                                        <a href="#">
                                            <div className="insta-box b-4">
                                                <img src={instaIcon} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="#">
                                            <div className="insta-box b-3">
                                                <img src={instaIcon} alt="" />
                                            </div>
                                        </a>
                                        <a href="#">
                                            <div className="insta-box b-4">
                                                <img src={instaIcon} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-3 d-flex d-md-block flex-column align-items-center text-center text-md-left ">
                            {/* <div className="info_form ">
                                <h5>
                                    Newsletter
                                </h5>
                                <form action="#">
                                    <input type="email" placeholder="Enter your email" />
                                    <button>
                                        Subscribe
                                    </button>
                                </form>
                                <div className="social_box">
                                    <a href="#">
                                        <img src="images/fb.png" alt="" />
                                    </a>
                                    <a href="#">
                                        <img src="images/twitter.png" alt="" />
                                    </a>
                                    <a href="#">
                                        <img src="images/linkedin.png" alt="" />
                                    </a>
                                    <a href="#">
                                        <img src="images/youtube.png" alt="" />
                                    </a>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0 d-flex d-md-block flex-column align-items-center text-center text-md-left ">
                            <div className="info_contact">
                                <h5>
                                    Contact Info
                                </h5>
                                <div>
                                    <div className="img-box">
                                        <img src={phoneIcon} width="12px" alt="" />
                                    </div>
                                    <a href='tel:+923074989564'>
                                        +92 307 4989564
                                    </a>
                                </div>
                                <div>
                                    <div className="img-box">
                                        <img src={emailIcon} width="18px" alt="" />
                                    </div>
                                    <a href='mailto:sales@stackiox.com'>
                                        sales@stackiox.com
                                    </a>
                                </div>
                                <div>
                                    <div className="img-box">
                                        <img src={locationIcon} width="18px" alt="" />
                                    </div>
                                    <p>
                                        Lahore, Pakistan
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* end info_section */}
            < section className="container-fluid footer_section" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-9 mx-auto">
                            <p>
                                © {new Date().getFullYear()} All Rights Reserved By <a href="https://stackiox.com/"><strong>Stackiox</strong></a>
                            </p>
                        </div>
                    </div>
                </div>
            </section >
        </div>
    );
};

export default Footer;