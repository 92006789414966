import { Link, useLocation } from "react-router-dom";
import ServicesData from "../data/services";

const Services = () => {
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/")[1];
    return (
        <section className="service_section layout_padding">
            <div className="container">
                <div className="heading_container">
                    <h2>
                        Our Services
                    </h2>
                </div>
                <div className="service_container">
                    {ServicesData.map((service, index) => (
                        <div key={index} className="box">
                            <div className="img-box">
                                <img src={service.image1Url} className="img1" alt="" />
                                <img src={service.image2Url} className="img2" alt="" />
                            </div>
                            <div className="detail-box">
                                <h5>
                                    {service.name}
                                </h5>
                                <p>
                                    {service.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                {
                    path !== "services" && (<div className="btn-box">
                        <Link to="/services">Read More</Link>
                    </div>)
                }
            </div>
        </section>
    );
};

export default Services;