import { Link, useLocation } from "react-router-dom";
import logo from '../images/stackiox.png';

const Navbar = () => {
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/")[1];

    return (
        <header className="header_section">
            <div className="container">
                <nav className="navbar navbar-expand-lg custom_nav-container ">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="Stackiox" height={33} />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="s-1"> </span>
                        <span className="s-2"> </span>
                        <span className="s-3"> </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                            <ul className="navbar-nav ">
                                <li className={path === "" ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                                </li>
                                <li className={path === "about" ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/about"> About</Link>
                                </li>
                                <li className={path === "services" ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/services"> Services </Link>
                                </li>
                                <li className={path === "portfolio" ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/portfolio"> Portfolio </Link>
                                </li>
                                <li className={path === "contact" ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/contact">Contact </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Navbar;