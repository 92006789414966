import About from "../components/About";
import ContactForm from "../components/ContactForm";
import HeroSlider from "../components/HeroSlider";
import Portfolio from "../components/Portfolio";
import Services from "../components/Services";

const Home = () => {
    return (
        <>
            <HeroSlider />
            <Services />
            <About />
            <Portfolio />
            <ContactForm />
        </>
    );
};

export default Home;